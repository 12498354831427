<template>
  <b-card class="program-update-tab-card">
    <b-card-title>
      <span v-b-tooltip.hover.top="'Program notifications'">
        Notifications
      </span>
    </b-card-title>
    <b-card-sub-title>
      Program notifications
    </b-card-sub-title>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="4">
          <b-form-group
            :label="'Inform ' + roleAlias(userRoles.MENTOR, program) + ' of locked match'"
            label-for="inform-mentor"
          >
            <b-form-checkbox
              id="informMentor"
              v-model="localProgram.inform_mentor_in_locked_match"
              name="inform-mentor"
              switch
              inline
              @change="onSubmit()"
            />
            <feather-icon
              v-b-tooltip.hover.right="'This adds the ability to send a notification to ' + roleAlias(userRoles.MENTOR, program) + ' if they are locked in a match'"
              size="20"
              class="align-top"
              icon="InfoIcon"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="isChampionPortal"
        class="mt-2"
      >
        <b-col
          sm="12"
          class="text-left"
        >
          <b-button
            variant="light"
            @click="onBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            Previous
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { integrations, integrationsDisplay, userRoles } from '@/models';
import programsService from "@/services/programsService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { mapGetters } from 'vuex';
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    program: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localProgram: this.program,
      isUpdating: false
    };
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
  },

  created() {
    
  },
  methods: {
    onBack() {
      this.$emit('back', '#general');
    },
    clearForm() {
      this.localProgram = this.program;
    },
    async onSubmit() {
      const { id } = this.localProgram;
      try {
        this.isUpdating = true;
        await programsService.updateProgram(id, {
          inform_mentor_in_locked_match: this.localProgram.inform_mentor_in_locked_match
        });
        this.$toast(makeSuccessToast('Notifications updated successfully.'));
        this.clearForm();
      } catch (e) {
        this.$toast(makeErrorToast('Something went wrong! Notifications not saved.'));
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      userRoles,
      roleAlias
    };
  }
};
</script>

<template>
  <training-form
    :isProgramTraining="isProgramTraining"
    :training="training"
    :program="program"
    @onSubmit="onSubmit"
    @cancel="cancel"
  />
</template>

<script>
import {
  VBModal,
} from "bootstrap-vue";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import _debounce from "lodash/debounce";
import Ripple from "vue-ripple-directive";
import trainingsService from "@/services/trainingsService";
import TrainingForm from "./TrainingForm.vue";

export default {
  components: {
    TrainingForm
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isProgramTraining: {
      type: Boolean
    },
    training: {
      type: Object,
      default: () => {},
    },
    program: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async onSubmit(data)  {
      this.isSubmitting = true;
      try {
        if (this.isProgramTraining) {
          await trainingsService.updateProgramTraining(
            this.$route.params.id,
            this.training.id,
            data
          );
        } else {
          await trainingsService.updateLibraryTraining(
            this.training.id,
            data
          );
        }

        this.$emit("trainingUpdated");
        this.$toast(makeSuccessToast("Training was successfully updated"));
      } catch (e) {
        this.$toast(makeErrorToast(e));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    clearForm() {
      this.selectedCourse = null;
      this.selectedUserRole = [];
    },
    cancel() {
      this.clearForm();
      this.$root.$emit("bv::toggle::collapse", "edit-training-right");
    }
  }
};
</script>
